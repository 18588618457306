import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import axios from "axios";
import apiConfig from "../../apiConfig";

import "./Signup.css";
import AddressAutocomplete from "../../components/Mapcomponent";

const Signup: React.FC = () => {

  const experienceDropdown = [
    { "id": '1', "experience": '1 Year' },
    { "id": '2', "experience": '2 Years' },
    { "id": '3', "experience": '3 Years' },
    { "id": '4', "experience": '4 Years' },
    { "id": '5', "experience": '5 Years' },
    { "id": '6', "experience": '6 Years' },
    { "id": '7', "experience": '7 Years' },
    { "id": '8', "experience": '8 Years' },
    { "id": '9', "experience": '9 Years' },
    { "id": '10', "experience": '10 Years' },
    { "id": '11', "experience": '11 Years' },
    { "id": '12', "experience": '12 Years' },
    { "id": '13', "experience": '13 Years' },
    { "id": '14', "experience": '14 Years' },
    { "id": '15', "experience": '15+ Years' }
  ]


  const history = useHistory();
  localStorage.setItem("fcmToken", "APA91bEVc2hGSDGG245gwrgrwgAI2SlP4fcvwegvfcVRERGwefvWsrgrgEGHwrgWRG235GWluCOviMsACgNZdcHEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
  const [formData, setFormData] = useState({
    "company_name": "",
    "firstname": "",
    "lastname": "",
    "email": "",
    "password": "",
    "user_type": 3,
    "fcm_token": localStorage.getItem('fcmToken'),
    "contact_number": "",
    "fb_url": "",
    "instagram": "",
    "actual_address": "",
    "experience": "",
    "lat": "",
    "lng": ""
  });

  const [showPassword, setShowPassword] = useState(false);
  const [resetInput, setResetInput] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');
  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [showToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [networkStatus, setNetworkStatus] = useState<any>(true);

  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState("");
  const [os, setOS] = useState('other');
  const [experienceList, setExperienceList] = useState<Array<any>>([]);

  useEffect(() => {
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

    if (macosPlatforms.includes(platform)) {
      setOS('macos');
    } else if (windowsPlatforms.includes(platform)) {
      setOS('windows');
    }

    getExperience();
    resetForm();

  }, []);

  const goBack = () => {
    resetForm();
    history.push('/login');
  };

  const resetForm = () => {
    setFormData({
      company_name: "",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      user_type: 3,
      fcm_token: localStorage.getItem('fcmToken'),
      contact_number: "",
      fb_url: "",
      instagram: "",
      actual_address: "",
      experience: "",
      lat: "",
      lng: ""
    });
    setIsChecked(false);
    setEmailError('');
    setPasswordError('');
    setPhoneError('');
    setResetInput(true); // Ensure this is set to true to trigger reset in AddressAutocomplete
  };

  const handlePlaceSelect = (place: any) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    setFormData({
      ...formData,
      actual_address: place.formatted_address,
      lat: latitude,
      lng: longitude
    });
  };

  const handleInputChange = () => {
    setFormData({
      ...formData,
      actual_address: "",
      lat: "",
      lng: ""
    });
  };

  const handleResetDone = () => {
    setResetInput(false);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleKeyPress = (event: any) => {
    if (!/[0-9]/.test(event.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChanges = (field: string, value: string) => {
    if (field === "email" || field === 'password') {
      value = value.replace(/\s+/g, '');
    }

    setFormData({
      ...formData,
      [field]: value,
    });

    if (field === "email") {
      setEmailError('');
    }

    if (field === 'password') {
      setPasswordError('');
    }

    if (field === 'contact_number') {
      if (/^[0-9]*$/.test(value)) {
        setPhoneError('');
      }
    }

    if (field === 'contact_number') {
      if (/^[0-9]*$/.test(value)) {
        setPhoneError('');
      }
    }

  };

  const getExperience = () => {

    // setLoading(true);
    // setBackdropVisible(true);

    axios.get(`${apiConfig.baseUrl}/experience`, {
      headers: {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
       setExperienceList(res.data.data);
      })
      .catch((error) => {
        console.log('Errorrrrr-->>', error);
        if (error.response.status == 401) {
          setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
          setSupendedTitle("Inactive");
         
          setShowSuspendedModal(true);
        }
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  const createAccount = () => {
    let emailValid = true;
    let passwordValid = true;
    let phoneValid = true;
    let addressValid = true;

    if (formData.experience !== "" && formData.email !== "" && formData.firstname !== "" && formData.lastname !== "" && formData.company_name !== "" && formData.password !== "" && formData.contact_number !== "" && isChecked) {
      if (formData.email === "") {
        setEmailError("Invalid Email address");
        emailValid = false;
      } else {
        setEmailError("");
      }

      if (formData.password === "") {
        setPasswordError("Password is required");
        passwordValid = false;
      } else {
        setPasswordError("");
      }

      const isValidPhoneNumber = formData.contact_number.length === 11 && formData.contact_number.startsWith('0');
      if (!isValidPhoneNumber) {
        setPhoneError(
          formData.contact_number.length !== 11
            ? 'Phone number should be 11 digits long'
            : 'Phone number should start with 0'
        );
        phoneValid = false;
      } else {
        setPhoneError('');
      }

      if (!formData.lat || !formData.lng || formData.actual_address === "") {
        setErrorMessage("Please select the address from the dropdown");
        addressValid = false;
      }

      if (emailValid && passwordValid && phoneValid && addressValid) {
        setLoading(true);
        setBackdropVisible(true);
        axios.post(`${apiConfig.baseUrl}/signup`, formData)
          .then(response => {
            if (response.data.status === false) {
              setShowErrorToast(true);
              setErrorMessage(response.data.message);
              setLoading(false);
              setBackdropVisible(false);
            } else {
              setShowToastSuccess(true);
              setSuccessMessage(response.data.message);
              resetForm();
              setResetInput(true);
              setShowPassword(false);
              history.push('/Successmodal');
              setLoading(false);
              setBackdropVisible(false);
            }

            setTimeout(() => {
              if (loading === true) {
                setLoading(false);
                setBackdropVisible(false);
                setErrorMessage("Something went wrong on API please try again");
                setShowErrorToast(true);
              }
            }, 10000);
          })
          .catch(error => {
            if (error.response.status === 401) {
              setSupendedMsg("Your account is inactive, please contact us on email - contact@glamdeva.com or whatsapp us at +447585633773 for any clarification.");
              setSupendedTitle("Inactive");
              setShowSuspendedModal(true);
            }
            if (error.code === "ERR_NETWORK") {
              setNetworkStatus(false);
            } else {
              setNetworkStatus(true);
            }
            setLoading(false);
            setBackdropVisible(false);
          });

      } else {
        if (!emailValid) {
          setErrorMessage("Please enter a valid email");
        } else if (!passwordValid) {
          setErrorMessage("Please enter a valid password");
        } else if (!phoneValid) {
          setErrorMessage("Please enter a valid Phone Number");
        }
        setShowErrorToast(true);
      }
    } else {
      setErrorMessage("Please enter the required fields and Agree to terms and conditions");
      setShowErrorToast(true);
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="light-mode-header-toolbar signup-toolbar">
          <IonButtons slot="start">
            <IonButton
              className="end-btn"
              onClick={goBack}
            >
              <IonIcon
                className="fav header-icons"
                src="assets/imgs/icons/backArrow.svg"
                slot="end" />
            </IonButton>
          </IonButtons>
          <IonTitle style={{ textAlign: "center" }}>
            Signup Here
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal" fullscreen>
        <div className="bg-signup"></div>
        <div className="gradient-overlay t-d-none m-d-none"></div>
        <IonImg
          style={{ margin: "3% 0% -9% 3%" }}
          className="afh-logo m-d-none"
          src="assets/imgs/images/Glamdeva-White-Logo.svg"
          alt="Logo Image"
          ></IonImg>
        <div className={`${os} the-form signup-grid`}>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonLabel className="m-d-none t-d-none d-title">Signup Form</IonLabel>
              </IonCol>
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Enter Business Name"
                    value={formData.company_name}
                    onChange={e => handleInputChanges('company_name', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Business Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Enter First Name"
                    value={formData.firstname}
                    onChange={e => handleInputChanges('firstname', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">First Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Enter Last Name"
                    value={formData.lastname}
                    onChange={e => handleInputChanges('lastname', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Last Name<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className='form-control-model-fp-signup fontName'
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={e => handleInputChanges('email', e.target.value)}
                  ></input>
                  {emailError && (<span className='fontName emailErrorMsg'>{emailError}</span>)}
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Email<span style={{ color: '#FF0000' }}>*</span></label>
                </div>
              </IonCol>
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    style={{ background: "rgba(255, 255, 255, 0)" }}
                    className='form-control-model-fp-signup fontName'
                    placeholder="Enter password"
                    value={formData.password}
                    onChange={e => handleInputChanges('password', e.target.value)}
                    onFocus={(e) => e.preventDefault()}
                  />
                  <IonIcon
                    className='eye-icon eye-icon-signup'
                    onClick={handleTogglePassword}
                    icon={showPassword ? eyeOffOutline : eyeOutline}
                  />
                  {passwordError && (<span className='fontName emailErrorMsg'>{passwordError}</span>)}
                  <label className="label-outline-model-fp-signup" htmlFor="password">Password</label>
                </div>
              </IonCol>
              <IonCol size="12">
                <div className="model-container-signup">
                  <input
                    className="form-control-model-fp-signup fontName"
                    placeholder="Enter Phone Number"
                    value={formData.contact_number}
                    minLength={11}
                    maxLength={11}
                    onChange={(e) => handleInputChanges('contact_number', e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    onFocus={(e) => e.preventDefault()}
                  ></input>
                  <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Phone Number<span style={{ color: '#FF0000' }}>*</span></label>
                  {phoneError && <p className='fontName emailErrorMsg'>{phoneError}</p>}
                </div>
              </IonCol>
              <IonCol size="12" col-12>
                      <div className="model-container-signup">
                        <IonSelect interface="popover" className='fontName form-control-model-fp-signup' placeholder="Select Experience" value={formData.experience} onIonChange={e => handleInputChanges('experience', e.detail.value)} >
                        
                        {experienceList.length !== 0 &&
                          experienceList.map((experience: any) => (
                            <IonSelectOption key={experience.id} value={experience.id}>{experience.experience}</IonSelectOption>
                          ))}

                          {experienceList.length == 0 &&
                          experienceDropdown.map((experience: any) => (
                            <IonSelectOption key={experience.id} value={experience.id}>{experience.experience}</IonSelectOption>
                          ))}

                        </IonSelect>
                        <label className="label-outline-model-fp-signup" htmlFor="textAreaExample">Years of Experience<span style={{ color: '#FF0000' }}>*</span></label>
                      </div>
                    </IonCol>
              <IonCol size="12">
                <AddressAutocomplete
                  onPlaceSelect={handlePlaceSelect}
                  resetInput={resetInput}
                  onResetDone={handleResetDone}
                  onInputChange={handleInputChange}
                />
              </IonCol>
              <IonCol size='12' col-12>
                <div className="checkbx-div">
                  <IonCheckbox
                    checked={isChecked}
                    className='fontName'
                    mode="ios"
                    onIonChange={handleCheckboxChange}
                  ></IonCheckbox>
                  <IonLabel className="checkbx-label">I Agree with the <span className='fontName'><a className="checkbx-span" href='https://glamdeva.com/terms'>Terms & Conditions</a></span></IonLabel>
                </div>
              </IonCol>
              <IonCol size='12' col-12>
                <div className="btn-holder btn-holder-signup">
                  <IonButton expand="block" onClick={createAccount} className="login-button">
                    Signup
                  </IonButton>
                  <IonLabel className="create-account">Already have a account? <Link style={{ textDecoration: "none" }} to="/login"><span className="create-account-span">Login</span></Link></IonLabel>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <IonModal isOpen={showSuspendedModal} id="example-modal-inactive" backdropDismiss={false}>
          <IonContent className="model-bg">
            <IonGrid className='cnfmModelGrid'>
              <IonRow>
                <IonCol size="12" col-12>
                  <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                </IonCol>
                <IonCol size="12" col-12>
                  <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                </IonCol>
                <IonCol size="12" col-12>
                  <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                </IonCol>
                <IonCol size='12' col-12>
                  <IonButton
                    routerLink="/login"
                    routerDirection="none"
                    style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => { setShowSuspendedModal(false) }} expand="block">
                    Back to Login
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonLoading
          isOpen={loading}
          spinner="circular"
          translucent={true}
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowErrorToast(false)}
          message={errorMessage}
          duration={3000}
          color="danger" />
        <IonToast
          isOpen={showToasSuccess}
          onDidDismiss={() => setShowToastSuccess(false)}
          message={successMessage}
          duration={3000}
          color="success" />
      </IonContent>
    </IonPage >
  );
};

export default Signup;
