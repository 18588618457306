import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import apiConfig from "../../apiConfig";
import { App as CapacitorApp } from "@capacitor/app";
import { Link } from "react-router-dom";
import "./Login.css";

import { Network } from "@capacitor/network";

const Login: React.FC = () => {
  const history = useHistory();

  const [darkModeValue, setDarkModeValue] = useState(
    localStorage.getItem("darkMode")
  );
  const [showBackAlert, setShowBackAlert] = useState(false);
  const [rememberStatus, setRememberStatus] = useState(Number);
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [iserror, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [inputEmail, setInputEmail] = useState("");
  const latestInputEmailValue = useRef("");
  const latestInputPasswordValue = useRef("");
  const [inputPassword, setInputPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState("");
  const [supendedTitle, setSupendedTitle] = useState("");

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [networkStatus, setNetworkStatus] = useState<any>(true);
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    localStorage.setItem("fcmToken", "APA91bEVc2hGSDGG245gwrgrwgAI2SlP4fcvwegvfcVRERGwefvWsrgrgEGHwrgWRG235GWluCOviMsACgNZdcHEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");

    if (darkModeValue == null) {
      localStorage.setItem("darkMode", "lightMode");
      setDarkModeValue("lightMode");
    }

    if (id) {
      console.log("Retrieved ID from URL:", id);

      localStorage.setItem("userImage", "");
      setLoading(true);
      setBackdropVisible(true);

      axios
        .post(`${apiConfig.baseUrl}/auto_login`, {
          id: id,
        })
        .then((res: any) => {
          if (res.data.suspendstatus == false) {
            setSupendedMsg(res.data.message);
            setSupendedTitle(res.data.title);
            setShowSuspendedModal(true);
            setLoading(false);
            setBackdropVisible(false);
          } else {
            if (res.data.status == true) {
              localStorage.setItem("accessToken", res.data.data.token);
              localStorage.setItem("tokenType", res.data.data.token_type);
              localStorage.setItem("userId", res.data.data.user.id);
              localStorage.setItem("userName", res.data.data.user.firstname);
              localStorage.setItem("lastName", res.data.data.user.lastname);
              localStorage.setItem("userImage", res.data.data.user.image);
              localStorage.setItem("userMail", res.data.data.user.email);
              console.log("image", res.data.data.user.image);

              setShowPassword(false);
              latestInputEmailValue.current = "";
              latestInputPasswordValue.current = "";
              setInputEmail("");
              setInputPassword("");
              setLoading(false);
              setBackdropVisible(false);
              // formState.emailError = '';
              localStorage.setItem("newUser", "yes");
              history.push("/tabs/dashboard");
            } else {
              setLoading(false);
              setBackdropVisible(false);
              setErrorMessage(res.data.message);
              setShowToast(true);
            }
          }
          setTimeout(() => {
            if (loading == true) {
              setLoading(false);
              setBackdropVisible(false);
              // setErrorMessage("Something went wrong on API please try again");
              // setShowToast(true);
            }
          }, 10000);
        })
        .catch((error) => {
          setLoading(false);
          setBackdropVisible(false);
          setErrorMessage(error.response.data.message[0].message);
          setShowToast(true);
          if (error.code == "ERR_NETWORK") {
            setNetworkStatus(false);
          } else {
            setNetworkStatus(true);
          }
          setMessage("Auth failure! Please create an account");
          setIserror(true);
        });
    }
  }, [id]);

  useEffect(() => {
    Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);

  const handleEmailInputChanges = (event: any) => {
    // Remove all spaces from email
    const email = event.target.value.replace(/\s+/g, "");
    setInputEmail(email);
    latestInputEmailValue.current = email;

    // Clear the email error if the email is corrected
    if (email) {
      setFormState((prevState) => ({
        ...prevState,
        emailError: "",
      }));
    }
  };

  const handleSubmit = (event: any) => {
    localStorage.setItem("userImage", "");

    event.preventDefault();

    let isValid = true;

    // Email validation logic
    if (!latestInputEmailValue.current) {
      setFormState((prevState) => ({
        ...prevState,
        emailError: "Please enter a valid email",
      }));
      isValid = false;
    } else {
      setFormState((prevState) => ({
        ...prevState,
        emailError: "",
      }));
    }

    // Password validation logic
    if (!latestInputPasswordValue.current || passwordError !== "") {
      setFormState((prevState) => ({
        ...prevState,
        passwordError: "Please enter a valid password",
      }));
      isValid = false;
    } else {
      setFormState((prevState) => ({
        ...prevState,
        passwordError: "",
      }));
    }

    // Stop the process if any validation fails
    if (!isValid) {
      setErrorMessage("Please fix the errors above");
      setShowToast(true);
      return;
    }

    // Proceed with the API call if no errors
    setLoading(true);
    setBackdropVisible(true);
    const loginData = {
      email: latestInputEmailValue.current,
      password: latestInputPasswordValue.current,
      fcm_token: localStorage.getItem("fcmToken"),
      user_type: 3,
      status: 1,
    };
    axios
      .post(`${apiConfig.baseUrl}/login`, loginData)
      .then((res: any) => {
        if (res.data.suspendstatus === false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          if (res.data.status === true) {
            localStorage.setItem("accessToken", res.data.data.token);
            localStorage.setItem("tokenType", res.data.data.token_type);
            localStorage.setItem("userId", res.data.data.user.id);
            localStorage.setItem("userName", res.data.data.user.firstname);
            localStorage.setItem("lastName", res.data.data.user.lastname);
            localStorage.setItem("userImage", res.data.data.user.image);
            localStorage.setItem("userMail", res.data.data.user.email);
            console.log("image", res.data.data.user.image);

            setShowPassword(false);
            latestInputEmailValue.current = "";
            latestInputPasswordValue.current = "";
            setInputEmail("");
            setInputPassword("");
            setLoading(false);
            setBackdropVisible(false);
            localStorage.setItem("newUser", "yes");
            localStorage.setItem("loggedOut", "");
            history.push("/tabs/dashboard");
          } else {
            setLoading(false);
            setBackdropVisible(false);
            setErrorMessage(res.data.message);
            setShowToast(true);
          }
        }
        setTimeout(() => {
          if (loading === true) {
            setLoading(false);
            setBackdropVisible(false);
          }
        }, 10000);
      })
      .catch((error) => {
        setLoading(false);
        setBackdropVisible(false);
        setErrorMessage(error.response.data.message[0].message);
        setShowToast(true);
        if (error.code === "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setMessage("Auth failure! Please create an account");
        setIserror(true);
      });
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleTogglePassword = () => {
    console.log("Before toggle:", showPassword);
    setShowPassword(!showPassword);
    console.log("After toggle:", !showPassword);
  };

  const handlePasswordInputChange = (event: any) => {
    // Remove all spaces from password
    const trimmedPassword = event.target.value.replace(/\s+/g, "");
    setInputPassword(trimmedPassword);
    latestInputPasswordValue.current = trimmedPassword;

    // Clear the password error if the password is not empty
    if (trimmedPassword) {
      setFormState((prevState) => ({
        ...prevState,
        passwordError: "",
      }));
    }
  };

  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" && (
        <div className="no-network-div">
          <IonImg
            className="no-network-img"
            src="assets/imgs/images/darkNoNetwork.svg"
          ></IonImg>
          <h6 style={{ color: "#CCCCCC" }} className="fontName">
            Check your internet connection and try again
          </h6>
        </div>
      )}
      {networkStatus == true && darkModeValue == "darkMode" && (
        <IonContent
          className="login ion-padding-horizontal overflow background-img"
          fullscreen
        >
          <div className="wrapper background-img-container-login">
            <IonImg
              src="assets/imgs/images/loginBg.png"
              alt="bg"
              className="product-image"
            ></IonImg>
            <div className="dark-card-container">
              <IonGrid>
                <IonRow>
                  <IonCol col-12 size="12">
                    <IonImg
                      className="afh-logo login-logo"
                      src="assets/imgs/images/dark-loginLogo.svg"
                      alt="Logo Image"
                    ></IonImg>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="m-title" style={{ margin: "1vh" }}>
                      <IonLabel className="dark-wlcm-title">
                        Welcome Back.
                      </IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <div className="the-form">
                <div className="input-holder">
                  <IonLabel className="dark-input-label">Email</IonLabel>
                  <IonItem
                    className="dark-login-ion-item input-item"
                    lines="none"
                  >
                    <input
                      type="email"
                      name="email"
                      className="dark-fontName dark-input login-input"
                      placeholder="Enter email"
                      value={inputEmail}
                      onChange={(e) => handleEmailInputChanges(e)}
                      onFocus={(e) => e.preventDefault()}
                    />
                    <IonIcon
                      slot="start"
                      style={{ fontSize: "17px", margin: "0px 10px 0px 0px" }}
                      className="fav"
                      src="assets/imgs/icons/dark-email.svg"
                    />
                  </IonItem>
                </div>
                {formState.emailError && (
                  <span
                    style={{ marginTop: "-2%" }}
                    className="dark-fontName emailErrorMsg"
                  >
                    {formState.emailError}
                  </span>
                )}
                <div className="input-holder">
                  <IonLabel className="dark-input-label">Password</IonLabel>
                  <IonItem
                    className="dark-login-ion-item input-item"
                    lines="none"
                  >
                    <input
                      type="text"
                      name="password"
                      className={
                        showPassword
                          ? "dark-input dark-fontName login-input passwordVisible"
                          : "dark-input dark-fontName login-input passwordHidden"
                      }
                      placeholder="Enter password"
                      value={inputPassword}
                      onChange={handlePasswordInputChange}
                      onFocus={(e) => e.preventDefault()}
                    />
                    <IonIcon
                      slot="start"
                      style={{ fontSize: "20px", margin: "0px 8px 0px 0px" }}
                      className="fav"
                      src="assets/imgs/icons/dark-password.svg"
                    />
                    <IonIcon
                      slot="end"
                      className="dark-eye-icon"
                      onClick={handleTogglePassword}
                      icon={showPassword ? eyeOffOutline : eyeOutline}
                    />
                  </IonItem>
                </div>
              </div>
              <div className="rem-holder">
                <IonRow>
                  <IonCol size="12" col-12>
                    <div className="remind-item-div">
                      {/* <IonItem className="rem-item dark-remind-item" lines="none">
                        <IonCheckbox onIonChange={checkboxChanged} className="rem-check" slot="start" mode="ios" />
                        <IonLabel className="dark-rem-label">Remember me</IonLabel>
                      </IonItem> */}
                      <Link to="/forgot-password">
                        <IonLabel class="dark-forgot-password">
                          Forgot Password?
                        </IonLabel>
                      </Link>
                    </div>
                  </IonCol>
                </IonRow>
              </div>

              <div className="btn-holder">
                <IonButton
                  expand="block"
                  className="login-button"
                  onClick={handleSubmit}
                >
                  Login
                </IonButton>
              </div>
              {/* </form> */}
            </div>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={errorMessage}
            duration={3000}
            color="danger"
          />
          <IonLoading isOpen={loading} spinner="circular" translucent={true} />
          <IonAlert
            isOpen={showBackAlert}
            onDidDismiss={() => setShowBackAlert(false)}
            header={"Exit App"}
            message={"Are you sure you want to exit the app?"}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                handler: () => {
                  setShowBackAlert(false);
                },
              },
              {
                text: "Exit",
                handler: () => {
                  CapacitorApp.exitApp();
                },
              },
            ]}
          />
          <IonModal
            isOpen={showSuspendedModal}
            id="dark-example-modal-inactive"
            backdropDismiss={false}
          >
            <IonContent className="dark-model-bg">
              <IonGrid className="cnfmModelGrid">
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonImg
                      src="assets/imgs/icons/warning.svg"
                      alt="Product"
                      className="tick-img"
                    ></IonImg>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className="fontName dark-b-cnfmed-title">
                      {supendedTitle}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className="fontName dark-thanks-cnfmed-title">
                      {supendedMsg}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonButton
                      routerLink="/login"
                      routerDirection="none"
                      style={{ width: "100%", margin: "auto" }}
                      className="fontName booking-details-btn"
                      onClick={() => setShowSuspendedModal(false)}
                      expand="block"
                    >
                      Back to Login
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
        </IonContent>
      )}

      {networkStatus == false && darkModeValue == "lightMode" && (
        <div className="no-network-div">
          <IonImg
            className="no-network-img"
            src="assets/imgs/images/noNetwork.svg"
          ></IonImg>
          <h6 className="fontName">
            Check your internet connection and try again
          </h6>
        </div>
      )}
      {networkStatus == true && darkModeValue == "lightMode" && (
        <IonContent
          className="login ion-padding-horizontal overflow background-img"
          fullscreen
        >
          <div className="wrapper background-img-container-login">
            <IonImg
              src="assets/imgs/images/loginBg.png"
              alt="bg"
              className="product-image login-img"
            ></IonImg>

            <div className="gradient-overlay m-d-none"></div>
            <IonImg
              src="assets/imgs/images/gd-landscape-image.jpg"
              alt="bg"
              className="login-landscape-image"
            ></IonImg>

            <div className="login-card-container">
              <IonGrid>
                <IonRow>
                  <IonCol col-12 size="12">
                    <IonImg
                      className="afh-logo login-logo"
                      src="assets/imgs/images/loginLogo.svg"
                      alt="Logo Image"
                    ></IonImg>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="m-title" style={{ margin: "0" }}>
                      <IonLabel className="wlcm-title fontName">
                        Welcome Back.
                      </IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <div className="the-form">
                <div className="model-container">
                  <input
                    className="form-control-model-fp-login fontName"
                    placeholder="Enter email"
                    value={inputEmail}
                    onChange={(e) => handleEmailInputChanges(e)}
                    onFocus={(e) => e.preventDefault()}
                    onKeyDown={handleKeyPress}
                  ></input>
                  <label
                    className="label-outline-model-fp-login"
                    htmlFor="textAreaExample"
                  >
                    Email
                  </label>
                </div>
                {formState.emailError && (
                  <span
                    style={{ marginTop: "-2%" }}
                    className="fontName emailErrorMsg"
                  >
                    {formState.emailError}
                  </span>
                )}

                <div className="model-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    style={{ background: "rgba(255, 255, 255, 0)" }}
                    className="form-control-model-fp-login fontName"
                    placeholder="Enter password"
                    value={inputPassword}
                    onChange={handlePasswordInputChange}
                    onKeyDown={handleKeyPress}
                    onFocus={undefined}
                  />
                  <IonIcon
                    className="eye-icon"
                    onClick={() => {
                      const inputField = document.querySelector(
                        'input[name="password"]'
                      );
                      if (inputField) {
                        const isPasswordVisible =
                          inputField.getAttribute("type") === "text";
                        inputField.setAttribute(
                          "type",
                          isPasswordVisible ? "password" : "text"
                        );
                        setShowPassword(!isPasswordVisible);
                      }
                    }}
                    icon={showPassword ? eyeOffOutline : eyeOutline}
                  />
                  <label
                    className="label-outline-model-fp-login"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  {formState.passwordError && (
                    <span className="fontName emailErrorMsg">
                      {formState.passwordError}
                    </span>
                  )}
                </div>
              </div>
              <div className="rem-holder">
                <IonRow>
                  <IonCol size="12" col-12>
                    <div className="remind-item-div">
                      <Link to="/forgot-password">
                        <IonLabel class="forgot-password">
                          Forgot Password?
                        </IonLabel>
                      </Link>
                    </div>
                  </IonCol>
                </IonRow>
              </div>

              <div className="btn-holder">
                <IonButton
                  expand="block"
                  className="login-button"
                  onClick={handleSubmit}
                >
                  Login
                </IonButton>
                <IonLabel className="create-account">
                  Don't have an account?{" "}
                  <Link style={{ textDecoration: "none" }} to="/signup">
                    <span className="create-account-span">SIGN UP</span>
                  </Link>
                </IonLabel>
              </div>
            </div>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={errorMessage}
            duration={3000}
            color="danger"
          />
          <IonLoading isOpen={loading} spinner="circular" translucent={true} />
          <IonAlert
            isOpen={showBackAlert}
            onDidDismiss={() => setShowBackAlert(false)}
            header={"Exit App"}
            message={"Are you sure you want to exit the app?"}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                handler: () => {
                  setShowBackAlert(false);
                },
              },
              {
                text: "Exit",
                handler: () => {
                  CapacitorApp.exitApp();
                },
              },
            ]}
          />
          <IonModal
            isOpen={showSuspendedModal}
            id="example-modal-inactive"
            backdropDismiss={false}
          >
            <IonContent className="model-bg">
              <IonGrid className="cnfmModelGrid">
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonImg
                      src="assets/imgs/icons/warning.svg"
                      alt="Product"
                      className="tick-img"
                    ></IonImg>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className="fontName b-cnfmed-title">
                      {supendedTitle}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className="fontName thanks-cnfmed-title">
                      {supendedMsg}
                    </IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonButton
                      routerLink="/login"
                      routerDirection="none"
                      style={{ width: "100%", margin: "auto" }}
                      className="fontName booking-details-btn"
                      onClick={() => setShowSuspendedModal(false)}
                      expand="block"
                    >
                      Back to Login
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
        </IonContent>
      )}
    </IonPage>
  );
};

export default Login;
